/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    OrderDetailsDto,
    OrderDetailsDtoFromJSON,
    OrderDetailsDtoToJSON,
    OrderManualPaymentUpdateRequestDto,
    OrderManualPaymentUpdateRequestDtoFromJSON,
    OrderManualPaymentUpdateRequestDtoToJSON,
    OrderPaymentDto,
    OrderPaymentDtoFromJSON,
    OrderPaymentDtoToJSON,
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusToJSON,
    OrderUpdateDeliveryRequestDto,
    OrderUpdateDeliveryRequestDtoFromJSON,
    OrderUpdateDeliveryRequestDtoToJSON,
    OrderUpdateExpressDeliveryRequestDto,
    OrderUpdateExpressDeliveryRequestDtoFromJSON,
    OrderUpdateExpressDeliveryRequestDtoToJSON,
    OrderUpdatePointsBonusRequestDto,
    OrderUpdatePointsBonusRequestDtoFromJSON,
    OrderUpdatePointsBonusRequestDtoToJSON,
    OrderUpdateRequestDto,
    OrderUpdateRequestDtoFromJSON,
    OrderUpdateRequestDtoToJSON,
    OrderUpdateSkuRequestDto,
    OrderUpdateSkuRequestDtoFromJSON,
    OrderUpdateSkuRequestDtoToJSON,
    OrderUpdateSplitRequestDto,
    OrderUpdateSplitRequestDtoFromJSON,
    OrderUpdateSplitRequestDtoToJSON,
    OrderUpdateType,
    OrderUpdateTypeFromJSON,
    OrderUpdateTypeToJSON,
    PaymentFlowAttachReceiptRequestDto,
    PaymentFlowAttachReceiptRequestDtoFromJSON,
    PaymentFlowAttachReceiptRequestDtoToJSON,
    PaymentFlowAvailablePaymentMethodsDto,
    PaymentFlowAvailablePaymentMethodsDtoFromJSON,
    PaymentFlowAvailablePaymentMethodsDtoToJSON,
    PaymentFlowCreatePaymentRequestDto,
    PaymentFlowCreatePaymentRequestDtoFromJSON,
    PaymentFlowCreatePaymentRequestDtoToJSON,
} from '../models';

export interface OrderRequest {
    id: string;
}

export interface OrderAttachReceiptRequest {
    id: string;
    paymentFlowAttachReceiptRequestDto: PaymentFlowAttachReceiptRequestDto;
}

export interface OrderAvailablePaymentMethodsRequest {
    id: string;
}

export interface OrderAvailablePaymentMethodsByPhoneRequest {
    phone: string;
}

export interface OrderCancelRequest {
    id: string;
}

export interface OrderCustomerPaymentRequest {
    id: string;
}

export interface OrderDeleteRequest {
    id: string;
}

export interface OrderNotifyRequest {
    id: string;
}

export interface OrderPaymentGenerateRequest {
    id: string;
    paymentFlowCreatePaymentRequestDto: PaymentFlowCreatePaymentRequestDto;
}

export interface OrderPaymentManualAttachReceiptRequest {
    id: string;
    orderManualPaymentUpdateRequestDto: OrderManualPaymentUpdateRequestDto;
}

export interface OrderPaymentMarkPaidRequest {
    id: string;
}

export interface OrderUpdateRequest {
    id: string;
    orderUpdateRequestDto: OrderUpdateRequestDto;
}

export interface OrderUpdateDeliveryRequest {
    id: string;
    orderUpdateDeliveryRequestDto: OrderUpdateDeliveryRequestDto;
}

export interface OrderUpdateExpressDeliveryRequest {
    id: string;
    orderUpdateExpressDeliveryRequestDto: OrderUpdateExpressDeliveryRequestDto;
}

export interface OrderUpdatePointsBonusRequest {
    id: string;
    orderUpdatePointsBonusRequestDto: OrderUpdatePointsBonusRequestDto;
}

export interface OrderUpdateSkuRequest {
    id: string;
    orderUpdateSkuRequestDto: OrderUpdateSkuRequestDto;
}

export interface OrderUpdateSplitRequest {
    id: string;
    orderUpdateSplitRequestDto: OrderUpdateSplitRequestDto;
}

export interface OrderUpdateTypeRequest {
    id: string;
    orderUpdateType: OrderUpdateType;
}

export interface OrdersRequest {
    statuses?: Array<OrderStatus>;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     */
    protected orderRequestOpts = (requestParameters: OrderRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling order.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderRaw = async (requestParameters: OrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const context = this.orderRequestOpts(requestParameters);
        return this.orderFetch(context, initOverrides);
    }

    /**
     */
    order = async (id: string, initOverrides?: RequestInit): Promise<OrderDetailsDto> => {
        const response = await this.orderRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useOrder = (() => {
        const key = (id: string) => this.orderRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.orderFetch(context));
        const fn = (id: string, config?: SWRConfiguration<OrderDetailsDto>): SWRResponse<OrderDetailsDto> => {
            return useSWR<OrderDetailsDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected orderAttachReceiptRequestOpts = (requestParameters: OrderAttachReceiptRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderAttachReceipt.');
        }

        if (requestParameters.paymentFlowAttachReceiptRequestDto === null || requestParameters.paymentFlowAttachReceiptRequestDto === undefined) {
            throw new runtime.RequiredError('paymentFlowAttachReceiptRequestDto','Required parameter requestParameters.paymentFlowAttachReceiptRequestDto was null or undefined when calling orderAttachReceipt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/payment/attach-receipt`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentFlowAttachReceiptRequestDtoToJSON(requestParameters.paymentFlowAttachReceiptRequestDto),
        };
    }

    /**
     */
    protected orderAttachReceiptFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderAttachReceiptRaw = async (requestParameters: OrderAttachReceiptRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderAttachReceiptRequestOpts(requestParameters);
        return this.orderAttachReceiptFetch(context, initOverrides);
    }

    /**
     */
    orderAttachReceipt = async (id: string, paymentFlowAttachReceiptRequestDto: PaymentFlowAttachReceiptRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderAttachReceiptRaw({ id: id, paymentFlowAttachReceiptRequestDto: paymentFlowAttachReceiptRequestDto }, initOverrides);
    }



    /**
     */
    protected orderAvailablePaymentMethodsRequestOpts = (requestParameters: OrderAvailablePaymentMethodsRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderAvailablePaymentMethods.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/order/{id}/payment/available-payment-methods`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderAvailablePaymentMethodsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowAvailablePaymentMethodsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFlowAvailablePaymentMethodsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderAvailablePaymentMethodsRaw = async (requestParameters: OrderAvailablePaymentMethodsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowAvailablePaymentMethodsDto>> => {
        const context = this.orderAvailablePaymentMethodsRequestOpts(requestParameters);
        return this.orderAvailablePaymentMethodsFetch(context, initOverrides);
    }

    /**
     */
    orderAvailablePaymentMethods = async (id: string, initOverrides?: RequestInit): Promise<PaymentFlowAvailablePaymentMethodsDto> => {
        const response = await this.orderAvailablePaymentMethodsRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useOrderAvailablePaymentMethods = (() => {
        const key = (id: string) => this.orderAvailablePaymentMethodsRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.orderAvailablePaymentMethodsFetch(context));
        const fn = (id: string, config?: SWRConfiguration<PaymentFlowAvailablePaymentMethodsDto>): SWRResponse<PaymentFlowAvailablePaymentMethodsDto> => {
            return useSWR<PaymentFlowAvailablePaymentMethodsDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected orderAvailablePaymentMethodsByAuthRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order/payment/available-payment-methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderAvailablePaymentMethodsByAuthFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowAvailablePaymentMethodsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFlowAvailablePaymentMethodsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderAvailablePaymentMethodsByAuthRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowAvailablePaymentMethodsDto>> => {
        const context = this.orderAvailablePaymentMethodsByAuthRequestOpts();
        return this.orderAvailablePaymentMethodsByAuthFetch(context, initOverrides);
    }

    /**
     */
    orderAvailablePaymentMethodsByAuth = async (initOverrides?: RequestInit): Promise<PaymentFlowAvailablePaymentMethodsDto> => {
        const response = await this.orderAvailablePaymentMethodsByAuthRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useOrderAvailablePaymentMethodsByAuth = (() => {
        const key = () => this.orderAvailablePaymentMethodsByAuthRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.orderAvailablePaymentMethodsByAuthFetch(context));
        const fn = (config?: SWRConfiguration<PaymentFlowAvailablePaymentMethodsDto>): SWRResponse<PaymentFlowAvailablePaymentMethodsDto> => {
            return useSWR<PaymentFlowAvailablePaymentMethodsDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected orderAvailablePaymentMethodsByPhoneRequestOpts = (requestParameters: OrderAvailablePaymentMethodsByPhoneRequest): runtime.RequestOpts => {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling orderAvailablePaymentMethodsByPhone.');
        }

        const queryParameters: any = {};

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/order/payment/available-payment-methods-by-phone`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderAvailablePaymentMethodsByPhoneFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowAvailablePaymentMethodsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFlowAvailablePaymentMethodsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderAvailablePaymentMethodsByPhoneRaw = async (requestParameters: OrderAvailablePaymentMethodsByPhoneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowAvailablePaymentMethodsDto>> => {
        const context = this.orderAvailablePaymentMethodsByPhoneRequestOpts(requestParameters);
        return this.orderAvailablePaymentMethodsByPhoneFetch(context, initOverrides);
    }

    /**
     */
    orderAvailablePaymentMethodsByPhone = async (phone: string, initOverrides?: RequestInit): Promise<PaymentFlowAvailablePaymentMethodsDto> => {
        const response = await this.orderAvailablePaymentMethodsByPhoneRaw({ phone: phone }, initOverrides);
        return await response.value();
    }


    /**
     */
    useOrderAvailablePaymentMethodsByPhone = (() => {
        const key = (phone: string) => this.orderAvailablePaymentMethodsByPhoneRequestOpts({ phone: phone });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.orderAvailablePaymentMethodsByPhoneFetch(context));
        const fn = (phone: string, config?: SWRConfiguration<PaymentFlowAvailablePaymentMethodsDto>): SWRResponse<PaymentFlowAvailablePaymentMethodsDto> => {
            return useSWR<PaymentFlowAvailablePaymentMethodsDto>(() => key(phone), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected orderCancelRequestOpts = (requestParameters: OrderCancelRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderCancel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderCancelFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderCancelRaw = async (requestParameters: OrderCancelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderCancelRequestOpts(requestParameters);
        return this.orderCancelFetch(context, initOverrides);
    }

    /**
     */
    orderCancel = async (id: string, initOverrides?: RequestInit): Promise<void> => {
        await this.orderCancelRaw({ id: id }, initOverrides);
    }



    /**
     */
    protected orderCreateRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderCreateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderCreateRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const context = this.orderCreateRequestOpts();
        return this.orderCreateFetch(context, initOverrides);
    }

    /**
     */
    orderCreate = async (initOverrides?: RequestInit): Promise<OrderDetailsDto> => {
        const response = await this.orderCreateRaw(initOverrides);
        return await response.value();
    }



    /**
     */
    protected orderCustomerPaymentRequestOpts = (requestParameters: OrderCustomerPaymentRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderCustomerPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/order/{id}/payment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderCustomerPaymentFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderPaymentDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderPaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderCustomerPaymentRaw = async (requestParameters: OrderCustomerPaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderPaymentDto>> => {
        const context = this.orderCustomerPaymentRequestOpts(requestParameters);
        return this.orderCustomerPaymentFetch(context, initOverrides);
    }

    /**
     */
    orderCustomerPayment = async (id: string, initOverrides?: RequestInit): Promise<OrderPaymentDto> => {
        const response = await this.orderCustomerPaymentRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useOrderCustomerPayment = (() => {
        const key = (id: string) => this.orderCustomerPaymentRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.orderCustomerPaymentFetch(context));
        const fn = (id: string, config?: SWRConfiguration<OrderPaymentDto>): SWRResponse<OrderPaymentDto> => {
            return useSWR<OrderPaymentDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected orderDeleteRequestOpts = (requestParameters: OrderDeleteRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderDeleteFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderDeleteRaw = async (requestParameters: OrderDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderDeleteRequestOpts(requestParameters);
        return this.orderDeleteFetch(context, initOverrides);
    }

    /**
     */
    orderDelete = async (id: string, initOverrides?: RequestInit): Promise<void> => {
        await this.orderDeleteRaw({ id: id }, initOverrides);
    }



    /**
     */
    protected orderNotifyRequestOpts = (requestParameters: OrderNotifyRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderNotify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order/{id}/notify`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderNotifyFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderNotifyRaw = async (requestParameters: OrderNotifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderNotifyRequestOpts(requestParameters);
        return this.orderNotifyFetch(context, initOverrides);
    }

    /**
     */
    orderNotify = async (id: string, initOverrides?: RequestInit): Promise<void> => {
        await this.orderNotifyRaw({ id: id }, initOverrides);
    }



    /**
     */
    protected orderPaymentGenerateRequestOpts = (requestParameters: OrderPaymentGenerateRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderPaymentGenerate.');
        }

        if (requestParameters.paymentFlowCreatePaymentRequestDto === null || requestParameters.paymentFlowCreatePaymentRequestDto === undefined) {
            throw new runtime.RequiredError('paymentFlowCreatePaymentRequestDto','Required parameter requestParameters.paymentFlowCreatePaymentRequestDto was null or undefined when calling orderPaymentGenerate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/payment-generate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentFlowCreatePaymentRequestDtoToJSON(requestParameters.paymentFlowCreatePaymentRequestDto),
        };
    }

    /**
     */
    protected orderPaymentGenerateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderPaymentDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderPaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderPaymentGenerateRaw = async (requestParameters: OrderPaymentGenerateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderPaymentDto>> => {
        const context = this.orderPaymentGenerateRequestOpts(requestParameters);
        return this.orderPaymentGenerateFetch(context, initOverrides);
    }

    /**
     */
    orderPaymentGenerate = async (id: string, paymentFlowCreatePaymentRequestDto: PaymentFlowCreatePaymentRequestDto, initOverrides?: RequestInit): Promise<OrderPaymentDto> => {
        const response = await this.orderPaymentGenerateRaw({ id: id, paymentFlowCreatePaymentRequestDto: paymentFlowCreatePaymentRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected orderPaymentManualAttachReceiptRequestOpts = (requestParameters: OrderPaymentManualAttachReceiptRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderPaymentManualAttachReceipt.');
        }

        if (requestParameters.orderManualPaymentUpdateRequestDto === null || requestParameters.orderManualPaymentUpdateRequestDto === undefined) {
            throw new runtime.RequiredError('orderManualPaymentUpdateRequestDto','Required parameter requestParameters.orderManualPaymentUpdateRequestDto was null or undefined when calling orderPaymentManualAttachReceipt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/payment/manual`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderManualPaymentUpdateRequestDtoToJSON(requestParameters.orderManualPaymentUpdateRequestDto),
        };
    }

    /**
     */
    protected orderPaymentManualAttachReceiptFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderPaymentDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderPaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderPaymentManualAttachReceiptRaw = async (requestParameters: OrderPaymentManualAttachReceiptRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderPaymentDto>> => {
        const context = this.orderPaymentManualAttachReceiptRequestOpts(requestParameters);
        return this.orderPaymentManualAttachReceiptFetch(context, initOverrides);
    }

    /**
     */
    orderPaymentManualAttachReceipt = async (id: string, orderManualPaymentUpdateRequestDto: OrderManualPaymentUpdateRequestDto, initOverrides?: RequestInit): Promise<OrderPaymentDto> => {
        const response = await this.orderPaymentManualAttachReceiptRaw({ id: id, orderManualPaymentUpdateRequestDto: orderManualPaymentUpdateRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected orderPaymentMarkPaidRequestOpts = (requestParameters: OrderPaymentMarkPaidRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderPaymentMarkPaid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/order/{id}/payment/mark-paid`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected orderPaymentMarkPaidFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderPaymentMarkPaidRaw = async (requestParameters: OrderPaymentMarkPaidRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderPaymentMarkPaidRequestOpts(requestParameters);
        return this.orderPaymentMarkPaidFetch(context, initOverrides);
    }

    /**
     */
    orderPaymentMarkPaid = async (id: string, initOverrides?: RequestInit): Promise<void> => {
        await this.orderPaymentMarkPaidRaw({ id: id }, initOverrides);
    }



    /**
     */
    protected orderUpdateRequestOpts = (requestParameters: OrderUpdateRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdate.');
        }

        if (requestParameters.orderUpdateRequestDto === null || requestParameters.orderUpdateRequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdateRequestDto','Required parameter requestParameters.orderUpdateRequestDto was null or undefined when calling orderUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateRequestDtoToJSON(requestParameters.orderUpdateRequestDto),
        };
    }

    /**
     */
    protected orderUpdateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdateRaw = async (requestParameters: OrderUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdateRequestOpts(requestParameters);
        return this.orderUpdateFetch(context, initOverrides);
    }

    /**
     */
    orderUpdate = async (id: string, orderUpdateRequestDto: OrderUpdateRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdateRaw({ id: id, orderUpdateRequestDto: orderUpdateRequestDto }, initOverrides);
    }



    /**
     */
    protected orderUpdateDeliveryRequestOpts = (requestParameters: OrderUpdateDeliveryRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdateDelivery.');
        }

        if (requestParameters.orderUpdateDeliveryRequestDto === null || requestParameters.orderUpdateDeliveryRequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdateDeliveryRequestDto','Required parameter requestParameters.orderUpdateDeliveryRequestDto was null or undefined when calling orderUpdateDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/delivery`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateDeliveryRequestDtoToJSON(requestParameters.orderUpdateDeliveryRequestDto),
        };
    }

    /**
     */
    protected orderUpdateDeliveryFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdateDeliveryRaw = async (requestParameters: OrderUpdateDeliveryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdateDeliveryRequestOpts(requestParameters);
        return this.orderUpdateDeliveryFetch(context, initOverrides);
    }

    /**
     */
    orderUpdateDelivery = async (id: string, orderUpdateDeliveryRequestDto: OrderUpdateDeliveryRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdateDeliveryRaw({ id: id, orderUpdateDeliveryRequestDto: orderUpdateDeliveryRequestDto }, initOverrides);
    }



    /**
     */
    protected orderUpdateExpressDeliveryRequestOpts = (requestParameters: OrderUpdateExpressDeliveryRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdateExpressDelivery.');
        }

        if (requestParameters.orderUpdateExpressDeliveryRequestDto === null || requestParameters.orderUpdateExpressDeliveryRequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdateExpressDeliveryRequestDto','Required parameter requestParameters.orderUpdateExpressDeliveryRequestDto was null or undefined when calling orderUpdateExpressDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/express-delivery`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateExpressDeliveryRequestDtoToJSON(requestParameters.orderUpdateExpressDeliveryRequestDto),
        };
    }

    /**
     */
    protected orderUpdateExpressDeliveryFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdateExpressDeliveryRaw = async (requestParameters: OrderUpdateExpressDeliveryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdateExpressDeliveryRequestOpts(requestParameters);
        return this.orderUpdateExpressDeliveryFetch(context, initOverrides);
    }

    /**
     */
    orderUpdateExpressDelivery = async (id: string, orderUpdateExpressDeliveryRequestDto: OrderUpdateExpressDeliveryRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdateExpressDeliveryRaw({ id: id, orderUpdateExpressDeliveryRequestDto: orderUpdateExpressDeliveryRequestDto }, initOverrides);
    }



    /**
     */
    protected orderUpdatePointsBonusRequestOpts = (requestParameters: OrderUpdatePointsBonusRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdatePointsBonus.');
        }

        if (requestParameters.orderUpdatePointsBonusRequestDto === null || requestParameters.orderUpdatePointsBonusRequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdatePointsBonusRequestDto','Required parameter requestParameters.orderUpdatePointsBonusRequestDto was null or undefined when calling orderUpdatePointsBonus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/points-bonus`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdatePointsBonusRequestDtoToJSON(requestParameters.orderUpdatePointsBonusRequestDto),
        };
    }

    /**
     */
    protected orderUpdatePointsBonusFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdatePointsBonusRaw = async (requestParameters: OrderUpdatePointsBonusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdatePointsBonusRequestOpts(requestParameters);
        return this.orderUpdatePointsBonusFetch(context, initOverrides);
    }

    /**
     */
    orderUpdatePointsBonus = async (id: string, orderUpdatePointsBonusRequestDto: OrderUpdatePointsBonusRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdatePointsBonusRaw({ id: id, orderUpdatePointsBonusRequestDto: orderUpdatePointsBonusRequestDto }, initOverrides);
    }



    /**
     */
    protected orderUpdateSkuRequestOpts = (requestParameters: OrderUpdateSkuRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdateSku.');
        }

        if (requestParameters.orderUpdateSkuRequestDto === null || requestParameters.orderUpdateSkuRequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdateSkuRequestDto','Required parameter requestParameters.orderUpdateSkuRequestDto was null or undefined when calling orderUpdateSku.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/update-sku`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateSkuRequestDtoToJSON(requestParameters.orderUpdateSkuRequestDto),
        };
    }

    /**
     */
    protected orderUpdateSkuFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdateSkuRaw = async (requestParameters: OrderUpdateSkuRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdateSkuRequestOpts(requestParameters);
        return this.orderUpdateSkuFetch(context, initOverrides);
    }

    /**
     */
    orderUpdateSku = async (id: string, orderUpdateSkuRequestDto: OrderUpdateSkuRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdateSkuRaw({ id: id, orderUpdateSkuRequestDto: orderUpdateSkuRequestDto }, initOverrides);
    }



    /**
     */
    protected orderUpdateSplitRequestOpts = (requestParameters: OrderUpdateSplitRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdateSplit.');
        }

        if (requestParameters.orderUpdateSplitRequestDto === null || requestParameters.orderUpdateSplitRequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdateSplitRequestDto','Required parameter requestParameters.orderUpdateSplitRequestDto was null or undefined when calling orderUpdateSplit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/split`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateSplitRequestDtoToJSON(requestParameters.orderUpdateSplitRequestDto),
        };
    }

    /**
     */
    protected orderUpdateSplitFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdateSplitRaw = async (requestParameters: OrderUpdateSplitRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdateSplitRequestOpts(requestParameters);
        return this.orderUpdateSplitFetch(context, initOverrides);
    }

    /**
     */
    orderUpdateSplit = async (id: string, orderUpdateSplitRequestDto: OrderUpdateSplitRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdateSplitRaw({ id: id, orderUpdateSplitRequestDto: orderUpdateSplitRequestDto }, initOverrides);
    }



    /**
     */
    protected orderUpdateTypeRequestOpts = (requestParameters: OrderUpdateTypeRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdateType.');
        }

        if (requestParameters.orderUpdateType === null || requestParameters.orderUpdateType === undefined) {
            throw new runtime.RequiredError('orderUpdateType','Required parameter requestParameters.orderUpdateType was null or undefined when calling orderUpdateType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order/{id}/update-type`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateTypeToJSON(requestParameters.orderUpdateType),
        };
    }

    /**
     */
    protected orderUpdateTypeFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected orderUpdateTypeRaw = async (requestParameters: OrderUpdateTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.orderUpdateTypeRequestOpts(requestParameters);
        return this.orderUpdateTypeFetch(context, initOverrides);
    }

    /**
     */
    orderUpdateType = async (id: string, orderUpdateType: OrderUpdateType, initOverrides?: RequestInit): Promise<void> => {
        await this.orderUpdateTypeRaw({ id: id, orderUpdateType: orderUpdateType }, initOverrides);
    }



    /**
     */
    protected ordersRequestOpts = (requestParameters: OrdersRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected ordersFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OrderDetailsDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDetailsDtoFromJSON));
    }

    /**
     */
    protected ordersRaw = async (requestParameters: OrdersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OrderDetailsDto>>> => {
        const context = this.ordersRequestOpts(requestParameters);
        return this.ordersFetch(context, initOverrides);
    }

    /**
     */
    orders = async (statuses?: Array<OrderStatus>, initOverrides?: RequestInit): Promise<Array<OrderDetailsDto>> => {
        const response = await this.ordersRaw({ statuses: statuses }, initOverrides);
        return await response.value();
    }


    /**
     */
    useOrders = (() => {
        const key = (statuses?: Array<OrderStatus>) => this.ordersRequestOpts({ statuses: statuses });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.ordersFetch(context));
        const fn = (statuses?: Array<OrderStatus>, config?: SWRConfiguration<Array<OrderDetailsDto>>): SWRResponse<Array<OrderDetailsDto>> => {
            return useSWR<Array<OrderDetailsDto>>(() => key(statuses), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}
