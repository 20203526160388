/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderReviewDto,
    CustomerOrderReviewDtoFromJSON,
    CustomerOrderReviewDtoFromJSONTyped,
    CustomerOrderReviewDtoToJSON,
} from './CustomerOrderReviewDto';
import {
    ImageDto,
    ImageDtoFromJSON,
    ImageDtoFromJSONTyped,
    ImageDtoToJSON,
} from './ImageDto';
import {
    OrderCreateV2DeliveryFromMskDto,
    OrderCreateV2DeliveryFromMskDtoFromJSON,
    OrderCreateV2DeliveryFromMskDtoFromJSONTyped,
    OrderCreateV2DeliveryFromMskDtoToJSON,
} from './OrderCreateV2DeliveryFromMskDto';
import {
    OrderCurrentPaymentDto,
    OrderCurrentPaymentDtoFromJSON,
    OrderCurrentPaymentDtoFromJSONTyped,
    OrderCurrentPaymentDtoToJSON,
} from './OrderCurrentPaymentDto';
import {
    OrderDetailsCustomerDto,
    OrderDetailsCustomerDtoFromJSON,
    OrderDetailsCustomerDtoFromJSONTyped,
    OrderDetailsCustomerDtoToJSON,
} from './OrderDetailsCustomerDto';
import {
    OrderDetailsDeliveryToMSK,
    OrderDetailsDeliveryToMSKFromJSON,
    OrderDetailsDeliveryToMSKFromJSONTyped,
    OrderDetailsDeliveryToMSKToJSON,
} from './OrderDetailsDeliveryToMSK';
import {
    OrderInfoDeliveryType,
    OrderInfoDeliveryTypeFromJSON,
    OrderInfoDeliveryTypeFromJSONTyped,
    OrderInfoDeliveryTypeToJSON,
} from './OrderInfoDeliveryType';
import {
    OrderSplitDto,
    OrderSplitDtoFromJSON,
    OrderSplitDtoFromJSONTyped,
    OrderSplitDtoToJSON,
} from './OrderSplitDto';
import {
    OrderStatus,
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import {
    OrderStatusSwitchDatesDto,
    OrderStatusSwitchDatesDtoFromJSON,
    OrderStatusSwitchDatesDtoFromJSONTyped,
    OrderStatusSwitchDatesDtoToJSON,
} from './OrderStatusSwitchDatesDto';

/**
 * 
 * @export
 * @interface OrderDetailsDto
 */
export interface OrderDetailsDto {
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    activeUntil: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productCategoryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productCategoryName?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderDetailsDto
     */
    status: OrderStatus;
    /**
     * 
     * @type {OrderInfoDeliveryType}
     * @memberof OrderDetailsDto
     */
    deliveryType?: OrderInfoDeliveryType;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    promoCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    promoCodeDiscount: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    oldPointsBonus: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    isPoizonUS: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    utm?: string | null;
    /**
     * 
     * @type {OrderDetailsDeliveryToMSK}
     * @memberof OrderDetailsDto
     */
    deliveryToMSK: OrderDetailsDeliveryToMSK;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    createdByCustomer: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    number: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productModel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productSize?: string | null;
    /**
     * 
     * @type {Array<ImageDto>}
     * @memberof OrderDetailsDto
     */
    productImages: Array<ImageDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDetailsDto
     */
    productPoizonImageUrls?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    step: number;
    /**
     * 
     * @type {OrderStatusSwitchDatesDto}
     * @memberof OrderDetailsDto
     */
    statusSwitchDate: OrderStatusSwitchDatesDto;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    paidAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryFio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryPhone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    deliveryCity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryCityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryCityRegionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryIssuingWarehouse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryIssuingWarehouseName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryIssuingWarehouseAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryCdekNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryCdekDeliveryCost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryCityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryCityRegionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryIssuingWarehouse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryIssuingWarehouseName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryIssuingWarehouseAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryBoxberryDeliveryCost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    deliveryComment?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDetailsDto
     */
    poizonReceiptImageIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    paid: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    total: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    discount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productPriceCNY?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    rateRUBCNY?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    productPriceRUB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    poizonDeliveryCost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    chinaMoscowDeliveryCost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    serviceCommission?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    pointsDiscount: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    pointsBonus: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    pointsBonusCount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    totalWithoutPointsDiscount: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    paymentStarted: boolean;
    /**
     * 
     * @type {OrderCurrentPaymentDto}
     * @memberof OrderDetailsDto
     */
    payment: OrderCurrentPaymentDto | null;
    /**
     * 
     * @type {OrderSplitDto}
     * @memberof OrderDetailsDto
     */
    split: OrderSplitDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    businessDiscount: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    expressDelivery: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    freeCdekDelivery: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    spuId: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    skuId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    fromAvailability: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    sentToCdek: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    pointsAvailableToCharge: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    pointsAvailableToGain: number;
    /**
     * 
     * @type {OrderCreateV2DeliveryFromMskDto}
     * @memberof OrderDetailsDto
     */
    deliveryFromMSK: OrderCreateV2DeliveryFromMskDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    delay: OrderDetailsDtoDelayEnum;
    /**
     * 
     * @type {CustomerOrderReviewDto}
     * @memberof OrderDetailsDto
     */
    review: CustomerOrderReviewDto | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    pointsForReviewText: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    pointsForReviewPhotos: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    approximateOnMoscowWarehouseAtMin: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    approximateOnMoscowWarehouseAtMax: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    approximateCompletedAtMin: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailsDto
     */
    approximateCompletedAtMax: Date | null;
    /**
     * 
     * @type {OrderDetailsCustomerDto}
     * @memberof OrderDetailsDto
     */
    customer: OrderDetailsCustomerDto | null;
}

/**
* @export
* @enum {string}
*/
export type OrderDetailsDtoDelayEnum =
    | 'NO'
    | 'MAYBE'
    | 'FACT'



export function OrderDetailsDtoFromJSON(json: any): OrderDetailsDto {
    return OrderDetailsDtoFromJSONTyped(json, false);
}

export function OrderDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeUntil': (json['activeUntil'] === null ? null : new Date(json['activeUntil'])),
        'productCategoryId': !exists(json, 'productCategoryId') ? undefined : json['productCategoryId'],
        'productCategoryName': !exists(json, 'productCategoryName') ? undefined : json['productCategoryName'],
        'status': OrderStatusFromJSON(json['status']),
        'deliveryType': !exists(json, 'deliveryType') ? undefined : OrderInfoDeliveryTypeFromJSON(json['deliveryType']),
        'promoCode': json['promoCode'],
        'promoCodeDiscount': json['promoCodeDiscount'],
        'oldPointsBonus': json['oldPointsBonus'],
        'isPoizonUS': json['isPoizonUS'],
        'utm': !exists(json, 'utm') ? undefined : json['utm'],
        'deliveryToMSK': OrderDetailsDeliveryToMSKFromJSON(json['deliveryToMSK']),
        'id': json['id'],
        'createdByCustomer': json['createdByCustomer'],
        'number': json['number'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'productModel': !exists(json, 'productModel') ? undefined : json['productModel'],
        'productUrl': !exists(json, 'productUrl') ? undefined : json['productUrl'],
        'productSize': !exists(json, 'productSize') ? undefined : json['productSize'],
        'productImages': ((json['productImages'] as Array<any>).map(ImageDtoFromJSON)),
        'productPoizonImageUrls': !exists(json, 'productPoizonImageUrls') ? undefined : json['productPoizonImageUrls'],
        'step': json['step'],
        'statusSwitchDate': OrderStatusSwitchDatesDtoFromJSON(json['statusSwitchDate']),
        'paidAt': (json['paidAt'] === null ? null : new Date(json['paidAt'])),
        'deliveryFio': !exists(json, 'deliveryFio') ? undefined : json['deliveryFio'],
        'deliveryPhone': !exists(json, 'deliveryPhone') ? undefined : json['deliveryPhone'],
        'deliveryCity': !exists(json, 'deliveryCity') ? undefined : json['deliveryCity'],
        'deliveryCityName': !exists(json, 'deliveryCityName') ? undefined : json['deliveryCityName'],
        'deliveryCityRegionName': !exists(json, 'deliveryCityRegionName') ? undefined : json['deliveryCityRegionName'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : json['deliveryAddress'],
        'deliveryIssuingWarehouse': !exists(json, 'deliveryIssuingWarehouse') ? undefined : json['deliveryIssuingWarehouse'],
        'deliveryIssuingWarehouseName': !exists(json, 'deliveryIssuingWarehouseName') ? undefined : json['deliveryIssuingWarehouseName'],
        'deliveryIssuingWarehouseAddress': !exists(json, 'deliveryIssuingWarehouseAddress') ? undefined : json['deliveryIssuingWarehouseAddress'],
        'deliveryCdekNumber': !exists(json, 'deliveryCdekNumber') ? undefined : json['deliveryCdekNumber'],
        'deliveryCdekDeliveryCost': !exists(json, 'deliveryCdekDeliveryCost') ? undefined : json['deliveryCdekDeliveryCost'],
        'deliveryBoxberryCity': !exists(json, 'deliveryBoxberryCity') ? undefined : json['deliveryBoxberryCity'],
        'deliveryBoxberryCityName': !exists(json, 'deliveryBoxberryCityName') ? undefined : json['deliveryBoxberryCityName'],
        'deliveryBoxberryCityRegionName': !exists(json, 'deliveryBoxberryCityRegionName') ? undefined : json['deliveryBoxberryCityRegionName'],
        'deliveryBoxberryAddress': !exists(json, 'deliveryBoxberryAddress') ? undefined : json['deliveryBoxberryAddress'],
        'deliveryBoxberryIssuingWarehouse': !exists(json, 'deliveryBoxberryIssuingWarehouse') ? undefined : json['deliveryBoxberryIssuingWarehouse'],
        'deliveryBoxberryIssuingWarehouseName': !exists(json, 'deliveryBoxberryIssuingWarehouseName') ? undefined : json['deliveryBoxberryIssuingWarehouseName'],
        'deliveryBoxberryIssuingWarehouseAddress': !exists(json, 'deliveryBoxberryIssuingWarehouseAddress') ? undefined : json['deliveryBoxberryIssuingWarehouseAddress'],
        'deliveryBoxberryNumber': !exists(json, 'deliveryBoxberryNumber') ? undefined : json['deliveryBoxberryNumber'],
        'deliveryBoxberryDeliveryCost': !exists(json, 'deliveryBoxberryDeliveryCost') ? undefined : json['deliveryBoxberryDeliveryCost'],
        'deliveryComment': !exists(json, 'deliveryComment') ? undefined : json['deliveryComment'],
        'poizonReceiptImageIds': !exists(json, 'poizonReceiptImageIds') ? undefined : json['poizonReceiptImageIds'],
        'paid': json['paid'],
        'total': json['total'],
        'discount': json['discount'],
        'productPriceCNY': !exists(json, 'productPriceCNY') ? undefined : json['productPriceCNY'],
        'rateRUBCNY': !exists(json, 'rateRUBCNY') ? undefined : json['rateRUBCNY'],
        'productPriceRUB': !exists(json, 'productPriceRUB') ? undefined : json['productPriceRUB'],
        'poizonDeliveryCost': !exists(json, 'poizonDeliveryCost') ? undefined : json['poizonDeliveryCost'],
        'chinaMoscowDeliveryCost': !exists(json, 'chinaMoscowDeliveryCost') ? undefined : json['chinaMoscowDeliveryCost'],
        'serviceCommission': !exists(json, 'serviceCommission') ? undefined : json['serviceCommission'],
        'pointsDiscount': json['pointsDiscount'],
        'pointsBonus': json['pointsBonus'],
        'pointsBonusCount': json['pointsBonusCount'],
        'totalWithoutPointsDiscount': json['totalWithoutPointsDiscount'],
        'paymentStarted': json['paymentStarted'],
        'payment': OrderCurrentPaymentDtoFromJSON(json['payment']),
        'split': OrderSplitDtoFromJSON(json['split']),
        'businessDiscount': json['businessDiscount'],
        'expressDelivery': json['expressDelivery'],
        'freeCdekDelivery': json['freeCdekDelivery'],
        'spuId': json['spuId'],
        'skuId': json['skuId'],
        'fromAvailability': json['fromAvailability'],
        'sentToCdek': json['sentToCdek'],
        'pointsAvailableToCharge': json['pointsAvailableToCharge'],
        'pointsAvailableToGain': json['pointsAvailableToGain'],
        'deliveryFromMSK': OrderCreateV2DeliveryFromMskDtoFromJSON(json['deliveryFromMSK']),
        'delay': json['delay'],
        'review': CustomerOrderReviewDtoFromJSON(json['review']),
        'pointsForReviewText': json['pointsForReviewText'],
        'pointsForReviewPhotos': json['pointsForReviewPhotos'],
        'approximateOnMoscowWarehouseAtMin': (json['approximateOnMoscowWarehouseAtMin'] === null ? null : new Date(json['approximateOnMoscowWarehouseAtMin'])),
        'approximateOnMoscowWarehouseAtMax': (json['approximateOnMoscowWarehouseAtMax'] === null ? null : new Date(json['approximateOnMoscowWarehouseAtMax'])),
        'approximateCompletedAtMin': (json['approximateCompletedAtMin'] === null ? null : new Date(json['approximateCompletedAtMin'])),
        'approximateCompletedAtMax': (json['approximateCompletedAtMax'] === null ? null : new Date(json['approximateCompletedAtMax'])),
        'customer': OrderDetailsCustomerDtoFromJSON(json['customer']),
    };
}

export function OrderDetailsDtoToJSON(value?: OrderDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeUntil': (value.activeUntil === null ? null : value.activeUntil.toISOString()),
        'productCategoryId': value.productCategoryId,
        'productCategoryName': value.productCategoryName,
        'status': OrderStatusToJSON(value.status),
        'deliveryType': OrderInfoDeliveryTypeToJSON(value.deliveryType),
        'promoCode': value.promoCode,
        'promoCodeDiscount': value.promoCodeDiscount,
        'oldPointsBonus': value.oldPointsBonus,
        'isPoizonUS': value.isPoizonUS,
        'utm': value.utm,
        'deliveryToMSK': OrderDetailsDeliveryToMSKToJSON(value.deliveryToMSK),
        'id': value.id,
        'createdByCustomer': value.createdByCustomer,
        'number': value.number,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'productModel': value.productModel,
        'productUrl': value.productUrl,
        'productSize': value.productSize,
        'productImages': ((value.productImages as Array<any>).map(ImageDtoToJSON)),
        'productPoizonImageUrls': value.productPoizonImageUrls,
        'step': value.step,
        'statusSwitchDate': OrderStatusSwitchDatesDtoToJSON(value.statusSwitchDate),
        'paidAt': (value.paidAt === null ? null : value.paidAt.toISOString()),
        'deliveryFio': value.deliveryFio,
        'deliveryPhone': value.deliveryPhone,
        'deliveryCity': value.deliveryCity,
        'deliveryCityName': value.deliveryCityName,
        'deliveryCityRegionName': value.deliveryCityRegionName,
        'deliveryAddress': value.deliveryAddress,
        'deliveryIssuingWarehouse': value.deliveryIssuingWarehouse,
        'deliveryIssuingWarehouseName': value.deliveryIssuingWarehouseName,
        'deliveryIssuingWarehouseAddress': value.deliveryIssuingWarehouseAddress,
        'deliveryCdekNumber': value.deliveryCdekNumber,
        'deliveryCdekDeliveryCost': value.deliveryCdekDeliveryCost,
        'deliveryBoxberryCity': value.deliveryBoxberryCity,
        'deliveryBoxberryCityName': value.deliveryBoxberryCityName,
        'deliveryBoxberryCityRegionName': value.deliveryBoxberryCityRegionName,
        'deliveryBoxberryAddress': value.deliveryBoxberryAddress,
        'deliveryBoxberryIssuingWarehouse': value.deliveryBoxberryIssuingWarehouse,
        'deliveryBoxberryIssuingWarehouseName': value.deliveryBoxberryIssuingWarehouseName,
        'deliveryBoxberryIssuingWarehouseAddress': value.deliveryBoxberryIssuingWarehouseAddress,
        'deliveryBoxberryNumber': value.deliveryBoxberryNumber,
        'deliveryBoxberryDeliveryCost': value.deliveryBoxberryDeliveryCost,
        'deliveryComment': value.deliveryComment,
        'poizonReceiptImageIds': value.poizonReceiptImageIds,
        'paid': value.paid,
        'total': value.total,
        'discount': value.discount,
        'productPriceCNY': value.productPriceCNY,
        'rateRUBCNY': value.rateRUBCNY,
        'productPriceRUB': value.productPriceRUB,
        'poizonDeliveryCost': value.poizonDeliveryCost,
        'chinaMoscowDeliveryCost': value.chinaMoscowDeliveryCost,
        'serviceCommission': value.serviceCommission,
        'pointsDiscount': value.pointsDiscount,
        'pointsBonus': value.pointsBonus,
        'pointsBonusCount': value.pointsBonusCount,
        'totalWithoutPointsDiscount': value.totalWithoutPointsDiscount,
        'paymentStarted': value.paymentStarted,
        'payment': OrderCurrentPaymentDtoToJSON(value.payment),
        'split': OrderSplitDtoToJSON(value.split),
        'businessDiscount': value.businessDiscount,
        'expressDelivery': value.expressDelivery,
        'freeCdekDelivery': value.freeCdekDelivery,
        'spuId': value.spuId,
        'skuId': value.skuId,
        'fromAvailability': value.fromAvailability,
        'sentToCdek': value.sentToCdek,
        'pointsAvailableToCharge': value.pointsAvailableToCharge,
        'pointsAvailableToGain': value.pointsAvailableToGain,
        'deliveryFromMSK': OrderCreateV2DeliveryFromMskDtoToJSON(value.deliveryFromMSK),
        'delay': value.delay,
        'review': CustomerOrderReviewDtoToJSON(value.review),
        'pointsForReviewText': value.pointsForReviewText,
        'pointsForReviewPhotos': value.pointsForReviewPhotos,
        'approximateOnMoscowWarehouseAtMin': (value.approximateOnMoscowWarehouseAtMin === null ? null : value.approximateOnMoscowWarehouseAtMin.toISOString()),
        'approximateOnMoscowWarehouseAtMax': (value.approximateOnMoscowWarehouseAtMax === null ? null : value.approximateOnMoscowWarehouseAtMax.toISOString()),
        'approximateCompletedAtMin': (value.approximateCompletedAtMin === null ? null : value.approximateCompletedAtMin.toISOString()),
        'approximateCompletedAtMax': (value.approximateCompletedAtMax === null ? null : value.approximateCompletedAtMax.toISOString()),
        'customer': OrderDetailsCustomerDtoToJSON(value.customer),
    };
}

