/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerPointLevel,
    CustomerPointLevelFromJSON,
    CustomerPointLevelFromJSONTyped,
    CustomerPointLevelToJSON,
} from './CustomerPointLevel';
import {
    OrderInfoDeliveryType,
    OrderInfoDeliveryTypeFromJSON,
    OrderInfoDeliveryTypeFromJSONTyped,
    OrderInfoDeliveryTypeToJSON,
} from './OrderInfoDeliveryType';

/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {CustomerPointLevel}
     * @memberof CustomerDto
     */
    level: CustomerPointLevel;
    /**
     * 
     * @type {OrderInfoDeliveryType}
     * @memberof CustomerDto
     */
    deliveryType?: OrderInfoDeliveryType;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    pinkFridayDiscount: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    telegramUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    telegramId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    telegramInviteLink?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    telegramBotStarted: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    photoUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    vK?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDto
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    pointsForOrder: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    orderLevel: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryFio?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    deliveryCity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryIssuingWarehouse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryCityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryCityRegionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryIssuingWarehouseName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deliveryIssuingWarehouseAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    utm?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    telegramBotTransferExecuted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    business: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDto
     */
    businessActiveUntil: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    businessOrderLevel: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    businessDiscount: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    actionNotifications: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    passportSeries?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    passportNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    passportIssueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    inn?: string | null;
}


export function CustomerDtoFromJSON(json: any): CustomerDto {
    return CustomerDtoFromJSONTyped(json, false);
}

export function CustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': CustomerPointLevelFromJSON(json['level']),
        'deliveryType': !exists(json, 'deliveryType') ? undefined : OrderInfoDeliveryTypeFromJSON(json['deliveryType']),
        'pinkFridayDiscount': json['pinkFridayDiscount'],
        'id': json['id'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'telegramUsername': !exists(json, 'telegramUsername') ? undefined : json['telegramUsername'],
        'telegramId': !exists(json, 'telegramId') ? undefined : json['telegramId'],
        'telegramInviteLink': !exists(json, 'telegramInviteLink') ? undefined : json['telegramInviteLink'],
        'telegramBotStarted': json['telegramBotStarted'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vK': !exists(json, 'VK') ? undefined : json['VK'],
        'createdAt': (new Date(json['createdAt'])),
        'points': json['points'],
        'pointsForOrder': json['pointsForOrder'],
        'orderLevel': json['orderLevel'],
        'deliveryPhone': !exists(json, 'deliveryPhone') ? undefined : json['deliveryPhone'],
        'deliveryFio': !exists(json, 'deliveryFio') ? undefined : json['deliveryFio'],
        'deliveryCity': !exists(json, 'deliveryCity') ? undefined : json['deliveryCity'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : json['deliveryAddress'],
        'deliveryIssuingWarehouse': !exists(json, 'deliveryIssuingWarehouse') ? undefined : json['deliveryIssuingWarehouse'],
        'deliveryCityName': !exists(json, 'deliveryCityName') ? undefined : json['deliveryCityName'],
        'deliveryCityRegionName': !exists(json, 'deliveryCityRegionName') ? undefined : json['deliveryCityRegionName'],
        'deliveryIssuingWarehouseName': !exists(json, 'deliveryIssuingWarehouseName') ? undefined : json['deliveryIssuingWarehouseName'],
        'deliveryIssuingWarehouseAddress': !exists(json, 'deliveryIssuingWarehouseAddress') ? undefined : json['deliveryIssuingWarehouseAddress'],
        'utm': !exists(json, 'utm') ? undefined : json['utm'],
        'telegramBotTransferExecuted': json['telegramBotTransferExecuted'],
        'business': json['business'],
        'businessActiveUntil': (json['businessActiveUntil'] === null ? null : new Date(json['businessActiveUntil'])),
        'businessOrderLevel': json['businessOrderLevel'],
        'businessDiscount': json['businessDiscount'],
        'actionNotifications': json['actionNotifications'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'passportSeries': !exists(json, 'passportSeries') ? undefined : json['passportSeries'],
        'passportNumber': !exists(json, 'passportNumber') ? undefined : json['passportNumber'],
        'passportIssueDate': !exists(json, 'passportIssueDate') ? undefined : json['passportIssueDate'],
        'inn': !exists(json, 'inn') ? undefined : json['inn'],
    };
}

export function CustomerDtoToJSON(value?: CustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': CustomerPointLevelToJSON(value.level),
        'deliveryType': OrderInfoDeliveryTypeToJSON(value.deliveryType),
        'pinkFridayDiscount': value.pinkFridayDiscount,
        'id': value.id,
        'phone': value.phone,
        'telegramUsername': value.telegramUsername,
        'telegramId': value.telegramId,
        'telegramInviteLink': value.telegramInviteLink,
        'telegramBotStarted': value.telegramBotStarted,
        'photoUrl': value.photoUrl,
        'name': value.name,
        'VK': value.vK,
        'createdAt': (value.createdAt.toISOString()),
        'points': value.points,
        'pointsForOrder': value.pointsForOrder,
        'orderLevel': value.orderLevel,
        'deliveryPhone': value.deliveryPhone,
        'deliveryFio': value.deliveryFio,
        'deliveryCity': value.deliveryCity,
        'deliveryAddress': value.deliveryAddress,
        'deliveryIssuingWarehouse': value.deliveryIssuingWarehouse,
        'deliveryCityName': value.deliveryCityName,
        'deliveryCityRegionName': value.deliveryCityRegionName,
        'deliveryIssuingWarehouseName': value.deliveryIssuingWarehouseName,
        'deliveryIssuingWarehouseAddress': value.deliveryIssuingWarehouseAddress,
        'utm': value.utm,
        'telegramBotTransferExecuted': value.telegramBotTransferExecuted,
        'business': value.business,
        'businessActiveUntil': (value.businessActiveUntil === null ? null : value.businessActiveUntil.toISOString()),
        'businessOrderLevel': value.businessOrderLevel,
        'businessDiscount': value.businessDiscount,
        'actionNotifications': value.actionNotifications,
        'email': value.email,
        'passportSeries': value.passportSeries,
        'passportNumber': value.passportNumber,
        'passportIssueDate': value.passportIssueDate,
        'inn': value.inn,
    };
}

