/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthTelegramOneTimeStateDto
 */
export interface AuthTelegramOneTimeStateDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramOneTimeStateDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthTelegramOneTimeStateDto
     */
    activated: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramOneTimeStateDto
     */
    customerId: string | null;
}


export function AuthTelegramOneTimeStateDtoFromJSON(json: any): AuthTelegramOneTimeStateDto {
    return AuthTelegramOneTimeStateDtoFromJSONTyped(json, false);
}

export function AuthTelegramOneTimeStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthTelegramOneTimeStateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'activated': json['activated'],
        'customerId': json['customerId'],
    };
}

export function AuthTelegramOneTimeStateDtoToJSON(value?: AuthTelegramOneTimeStateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'activated': value.activated,
        'customerId': value.customerId,
    };
}

