/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    PassportIsValidDto,
    PassportIsValidDtoFromJSON,
    PassportIsValidDtoToJSON,
    UpdatePassportByOrderIdRequestDto,
    UpdatePassportByOrderIdRequestDtoFromJSON,
    UpdatePassportByOrderIdRequestDtoToJSON,
    UpdatePassportRequestDto,
    UpdatePassportRequestDtoFromJSON,
    UpdatePassportRequestDtoToJSON,
} from '../models';

export interface PassportIsValidByOrderIdRequest {
    orderId: string;
}

export interface PassportUpdateRequest {
    updatePassportRequestDto: UpdatePassportRequestDto;
}

export interface PassportUpdateByOrderIdRequest {
    updatePassportByOrderIdRequestDto: UpdatePassportByOrderIdRequestDto;
}

/**
 * 
 */
export class PassportApi extends runtime.BaseAPI {

    /**
     */
    protected passportIsValidRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/passport/is-valid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected passportIsValidFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PassportIsValidDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PassportIsValidDtoFromJSON(jsonValue));
    }

    /**
     */
    protected passportIsValidRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<PassportIsValidDto>> => {
        const context = this.passportIsValidRequestOpts();
        return this.passportIsValidFetch(context, initOverrides);
    }

    /**
     */
    passportIsValid = async (initOverrides?: RequestInit): Promise<PassportIsValidDto> => {
        const response = await this.passportIsValidRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    usePassportIsValid = (() => {
        const key = () => this.passportIsValidRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.passportIsValidFetch(context));
        const fn = (config?: SWRConfiguration<PassportIsValidDto>): SWRResponse<PassportIsValidDto> => {
            return useSWR<PassportIsValidDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected passportIsValidByOrderIdRequestOpts = (requestParameters: PassportIsValidByOrderIdRequest): runtime.RequestOpts => {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling passportIsValidByOrderId.');
        }

        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/passport/is-valid-by-order-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected passportIsValidByOrderIdFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PassportIsValidDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PassportIsValidDtoFromJSON(jsonValue));
    }

    /**
     */
    protected passportIsValidByOrderIdRaw = async (requestParameters: PassportIsValidByOrderIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PassportIsValidDto>> => {
        const context = this.passportIsValidByOrderIdRequestOpts(requestParameters);
        return this.passportIsValidByOrderIdFetch(context, initOverrides);
    }

    /**
     */
    passportIsValidByOrderId = async (orderId: string, initOverrides?: RequestInit): Promise<PassportIsValidDto> => {
        const response = await this.passportIsValidByOrderIdRaw({ orderId: orderId }, initOverrides);
        return await response.value();
    }


    /**
     */
    usePassportIsValidByOrderId = (() => {
        const key = (orderId: string) => this.passportIsValidByOrderIdRequestOpts({ orderId: orderId });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.passportIsValidByOrderIdFetch(context));
        const fn = (orderId: string, config?: SWRConfiguration<PassportIsValidDto>): SWRResponse<PassportIsValidDto> => {
            return useSWR<PassportIsValidDto>(() => key(orderId), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected passportUpdateRequestOpts = (requestParameters: PassportUpdateRequest): runtime.RequestOpts => {
        if (requestParameters.updatePassportRequestDto === null || requestParameters.updatePassportRequestDto === undefined) {
            throw new runtime.RequiredError('updatePassportRequestDto','Required parameter requestParameters.updatePassportRequestDto was null or undefined when calling passportUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/passport/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePassportRequestDtoToJSON(requestParameters.updatePassportRequestDto),
        };
    }

    /**
     */
    protected passportUpdateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected passportUpdateRaw = async (requestParameters: PassportUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.passportUpdateRequestOpts(requestParameters);
        return this.passportUpdateFetch(context, initOverrides);
    }

    /**
     */
    passportUpdate = async (updatePassportRequestDto: UpdatePassportRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.passportUpdateRaw({ updatePassportRequestDto: updatePassportRequestDto }, initOverrides);
    }



    /**
     */
    protected passportUpdateByOrderIdRequestOpts = (requestParameters: PassportUpdateByOrderIdRequest): runtime.RequestOpts => {
        if (requestParameters.updatePassportByOrderIdRequestDto === null || requestParameters.updatePassportByOrderIdRequestDto === undefined) {
            throw new runtime.RequiredError('updatePassportByOrderIdRequestDto','Required parameter requestParameters.updatePassportByOrderIdRequestDto was null or undefined when calling passportUpdateByOrderId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/passport/update-by-order-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePassportByOrderIdRequestDtoToJSON(requestParameters.updatePassportByOrderIdRequestDto),
        };
    }

    /**
     */
    protected passportUpdateByOrderIdFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected passportUpdateByOrderIdRaw = async (requestParameters: PassportUpdateByOrderIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.passportUpdateByOrderIdRequestOpts(requestParameters);
        return this.passportUpdateByOrderIdFetch(context, initOverrides);
    }

    /**
     */
    passportUpdateByOrderId = async (updatePassportByOrderIdRequestDto: UpdatePassportByOrderIdRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.passportUpdateByOrderIdRaw({ updatePassportByOrderIdRequestDto: updatePassportByOrderIdRequestDto }, initOverrides);
    }



}
