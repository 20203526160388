/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePassportRequestDto
 */
export interface UpdatePassportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    deliveryPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    deliveryFio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    passportSeries?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    passportNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    passportIssueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassportRequestDto
     */
    inn?: string | null;
}


export function UpdatePassportRequestDtoFromJSON(json: any): UpdatePassportRequestDto {
    return UpdatePassportRequestDtoFromJSONTyped(json, false);
}

export function UpdatePassportRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePassportRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'deliveryPhone': !exists(json, 'deliveryPhone') ? undefined : json['deliveryPhone'],
        'deliveryFio': !exists(json, 'deliveryFio') ? undefined : json['deliveryFio'],
        'passportSeries': !exists(json, 'passportSeries') ? undefined : json['passportSeries'],
        'passportNumber': !exists(json, 'passportNumber') ? undefined : json['passportNumber'],
        'passportIssueDate': !exists(json, 'passportIssueDate') ? undefined : json['passportIssueDate'],
        'inn': !exists(json, 'inn') ? undefined : json['inn'],
    };
}

export function UpdatePassportRequestDtoToJSON(value?: UpdatePassportRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'deliveryPhone': value.deliveryPhone,
        'deliveryFio': value.deliveryFio,
        'passportSeries': value.passportSeries,
        'passportNumber': value.passportNumber,
        'passportIssueDate': value.passportIssueDate,
        'inn': value.inn,
    };
}

